import { UserBase } from "../json-schemas/base/user"
import { httpDelete, httpPost } from "../utils/http.service"
import { I18nService } from "../utils/i18n.service"
import { MarketType } from "./suppliers.service"

const i18nScoped = new I18nService(require('./users.service.yml'))

// Official HTML Spec WHATWG regex from https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
export const emailRFC1123 = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export async function userDepersonate () {
  const { url } = await httpDelete(`/users/${window.currentUser.id}/usurpation`)
  window.location.href = url
}

export function userSignIn (body: { email: string, password: string, market_id?: number, market_type?: MarketType }) {
  body.market_id ||= +window.market_id
  body.market_type ||= window.market_type
  return httpPost('/users/sign_in', { user: body })
}

export function userSignOut () {
  return fetch('/users/sign_out')
}

export function userForgotPassword (body: { email: string, market_id?: number, market_type?: MarketType }) {
  body.market_id ||= +window.market_id
  body.market_type ||= window.market_type
  return httpPost('/users/password', { user: body })
}

export function usersI18nType (type: string) {
  return i18nScoped.t('user_type.' + type.toLocaleLowerCase())
}

export function usersI18nCivility (civility: UserBase['civility']) {
  return i18nScoped.t(`civility.${civility}`)
}